export const newsText1 =
  "Meister Michael Christmann kennt die Preisuntergrenze seines Betriebs genau. Das hilft ihm Aufträge auszusortieren, die sich für seinen Betrieb nicht lohnen."

export const newsText2 =
  "Nicht jeder Auftrag rechnet sich für Ihren Handwerksbetrieb. 3 Zahlen sollten Sie kennen, um unrentable Angebote auszusortieren."

export const newsText3 =
  "Was zunächst eine Notlösung war, ist nun ein fester Bestandteil des e-masters Weiterbildungsangebots: digitale Seminare."

export const newsText4 =
  "Mit der Bierdeckelkalkulation von Fachhandwerk 360° können Betriebe ihre Profitabilität effektiv steuern. Ab sofort bietet das Unternehmen kostenlose Seminare zu dem Thema in der Community von Hero Software an – und beantwortet Fragen zu kaufmännischen Abläufen und Herausforderungen im Bauhandwerk."

export const newsText5 =
  "Fachhandwerk 360° ist kaufmännischer Experte und unterstützt ab sofort Handwerksbetriebe in der Hero-Community."

export const newsText6 =
  "Neben dem Netzwerk und Austausch in der eigenen Branche bietet das soziale Netzwerk für Handwerker ab sofort auch verifiziertes Expertenwissen für seine Mitglieder an."

export const newsText7 =
  "Stellen Sie sich vor, 60 Prozent Ihrer Fachkräfte verlassen plötzlich Ihren Betrieb – wegen Geld. Theodor Röhm ist das passiert. Es war ein Weckruf."

export const newsText8 =
  "Bierdeckelkalkulation? Das sind drei Zahlen, die allesamt auf einen Bierdeckel passen und am Ende für mehr Wirtschaftlichkeit in deinem Betrieb sorgen. (Seite 38 | 39)"

export const newsText9 =
  "Wer seine Vollkosten und verkäuflichen Stunden nicht kennt, belügt sich selbst – und schuftet sich in die Armut, warnt Rainer Dreier. Rechnung gefällig?"

export const newsText10 =
  "Mit dem Weggang zweier Mitarbeiter änderte sich die Kostenstruktur dieses Betriebs. Eine Herausforderung, der er gezielt begegnete. Dank einer soliden Kalkulation."

export const newsText11 =
  "Wer Angebote im Blindflug abgibt, verschenkt Geld und gerät leicht in wirtschaftliche Schieflage. Sind Sie gegen diese 10 Fehler in der Kalkulation gewappnet?"

export const newsText12 =
  "Fensterspezialist Moritz Hildebrandt schafft Kostentransparenz in seinem Handwerksbetrieb. Seine einfache Lösung: die Bierdeckel-Kalkulation."
